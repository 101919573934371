import React, { useState } from 'react';
import styled from 'styled-components';
import { color, layout, pageMargins, typography } from "../../shared/styles"
import { Link } from "gatsby"

const TitleLink = styled(Link)`
  color: ${color.textPrimary};
`;

const Title = styled.div`
  font-size: ${typography.size.h4}px;
  font-weight: ${typography.weight.semibold};

  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${layout.columnPaddingNormal}px;
  padding-right: ${layout.columnPaddingNormal + 28}px;

  position: relative;
  cursor: pointer;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto 0 auto auto;
    width: 28px;
    height: 28px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`;

const Desc = styled.div`
  font-size: ${typography.size.p1}px;
  line-height: 1.5;

  padding: 0 ${layout.columnPaddingNormal}px;
  margin-top: 16px;
  margin-bottom: 16px;

  color: ${color.textSecondary};
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #d3d9e1;

  padding: 16px 0;
  
  margin: 0 ${layout.columnPaddingNormal}px;

  position: relative;

  ${Title}::after {
    background: url(${(props) => (props.expanded ? '/images/contract.svg' : '/images/expand.svg')});
  }
`;

export default function FaqItem({ title, desc, expanded }) {
  const [isExpanded, setExpanded] = useState(expanded);

  const toggle = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Wrapper expanded={isExpanded}>
      <Title onClick={toggle}>
        <TitleLink href="#test">{title}</TitleLink>
      </Title>
      {desc && isExpanded && <Desc>{desc}</Desc>}
    </Wrapper>
  );
}
