import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { background, breakpoint, color, layout, pageMargins, spacing, typography } from "../../shared/styles"
import ColumnLayout from "../../layout/ColumnLayout"
import Section from "../../layout/Section"
import AppStoreBadgeSVG from "../../../assets/app_store_badge.svg"
import PlayBadgeSVG from "../../../assets/play_badge.svg"
import HomepageHeroBackgroundPNG from "../../../assets/homepage-hero-background.png"
import InputButton from "../../InputButton"

const HeroTitle = styled.section`
  text-align: center;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    text-align: left;
    padding-right: 58px;
  }
  z-index: 2;
  
  padding-left: ${layout.columnPaddingNormal}px;
  padding-right: ${layout.columnPaddingNormal}px;
`;

const Title = styled.h1`
  font-weight: ${typography.weight.bold};
  font-size: 38px;
  line-height: 1.2;
  margin-top: 80px;
  margin-bottom: 16px;
`;

const Subtitle = styled.div`
  font-size: ${typography.size.p1}px;
  line-height: 1.5;
  color: ${color.textSecondary};
  margin-bottom: 1rem;
`;

const Left = styled.div`
  max-width: ${breakpoint}px;
`;

const Wrapper = styled(Section)`
  background-image: url();
  background-repeat: no-repeat;*/
  background-color: ${background.bg};
`;

const AppStoreBadges = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  svg + svg {
    margin-top: 8px;
  }

  @media (min-width: ${layout.viewportWidthMedium}px) {
    justify-content: flex-start;
  }

  @media (min-width: ${layout.viewportWidthSmall}px) {
    flex-direction: row;
    svg + svg {
      margin-left: 14px;
      margin-top: 0;
    }
  }
`;

const HeroGraphicBackground = styled.div`
  position: absolute;
  top: 0;
  left: -200px;

  width: 889px;
  height: 692px;
  background-image: url(${HomepageHeroBackgroundPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const HeroGraphic = styled.div`
  position: relative;
  z-index: 1;
  
  user-select: none;
  
  height: 250px;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    height: 580px;
  }
`;

const SendLinkForm = styled.form`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  
  @media (min-width: ${layout.viewportWidthMedium}px) {
    flex-direction: row;
  }
`;

const PhoneInput = styled.input`
  width: 100%;
  
  appearance: none;

  color: ${color.textSecondary};
  font-size: ${typography.size.p2}px;
  line-height: 1.5;
  
  padding: 8px 12px;
  
  margin-bottom: 8px;
  
  @media (min-width: ${layout.viewportWidthMedium}px) {
    margin-right: 14px;
    margin-bottom: 0px;
  }

  border: 1px solid #D3D9E1;
  border-radius: ${spacing.borderRadius.default}px;
`;

export default function Hero() {
  return (
    <>
      <Section backgroundColor={background.bg}>
        <ColumnLayout columns="1,1">
          <HeroTitle>
            <Title>Share money and pay together without the awkwardness</Title>
            <Subtitle>
              Splyt makes it easy for groups to save, share, and split expenses like meals, rent, travel, and more.
            </Subtitle>
            <SendLinkForm>
              <PhoneInput type="text" value="Phone number" />
              <InputButton type="submit" value="Send Link" />
            </SendLinkForm>
            <AppStoreBadges>
              <AppStoreBadgeSVG width="148" />
              <PlayBadgeSVG width="148" />
            </AppStoreBadges>
          </HeroTitle>
          <HeroGraphic>
            <HeroGraphicBackground></HeroGraphicBackground>
          </HeroGraphic>
        </ColumnLayout>
      </Section>
    </>
  );
}
