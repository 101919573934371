import React from 'react';
import styled from 'styled-components';
import { breakpoint, layout, pageMargins, typography } from "../../shared/styles"
import StarSVG from "../../../assets/star.svg"

const Rating = styled.div`
  margin: 16px 0;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  
  div {
    margin-right: 12px;
  }
`;

const Wrapper = styled.div`
  font-size: ${typography.size.p2};
  line-height: 1.5;

  padding: ${layout.columnPaddingNormal}px;

  margin: 0 8px;

  background: #ffffff;
  box-shadow: 0px 6px 24px rgba(23, 41, 75, 0.08);
  border-radius: 12px;
`;

export default function Testimonial({ children, rating, authorAvatar, authorName }) {
  return (
    <Wrapper>
      <div>
        "{children}"
      </div>
      <Rating>
        <StarSVG /> <StarSVG /> <StarSVG /> <StarSVG /> <StarSVG />
      </Rating>
      <Author>{authorAvatar} {authorName}</Author>
    </Wrapper>
  );
}
