import React from 'react';
import styled, { css } from 'styled-components';

const Speaker = styled.div`
  width: 32px;
  height: 4px;
  background: #ECEFF2;
  
  margin: 12px auto 0;
  
  border-radius: 4px;
`;

const Bevel = styled.div`
  width: 123px;
  height: 29px;
  background: #F9FAFD;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  
  border-radius: 13px;
`;

const Content = styled.div`
  width: 246px;
  height: 532px;
  background: #FFFFFF;
  
  border-radius: 25px;
  overflow: hidden;
`;

const Inner = styled.div`
  position: relative;

  width: 266px;
  height: 556px;
  background-color: #F9FAFD;
  padding: 10px 8px;
  
  border-radius: 32px;
  border: 2px solid #F1F3F6;
`;

const Wrapper = styled.div`
  width: 274px;
  height: 564px;
  
  border-radius: 32px;
  
  background: linear-gradient(360deg, rgba(237, 240, 243, 0) 99.02%, rgba(237, 240, 243, 0.8) 101.06%);
  padding: 4px;
  
  box-shadow: inset 0px 1px 8px #E6E9EC;
`;

export default function Phone({children}) {
  return (
    <Wrapper>
      <Inner>
        <Bevel>
          <Speaker/>
        </Bevel>
        <Content>
          {children}
        </Content>
      </Inner>
    </Wrapper>
  );
}