import React from 'react';

import IndexScreen from '../components/screens/IndexScreen/IndexScreen';
import { GlobalStyle } from '../components/shared/global';
import SiteHeader from "../components/layout/SiteHeader"

export default () => (
  <>
    <GlobalStyle />
    <SiteHeader />
    <main>
      <IndexScreen />
    </main>
  </>
);
