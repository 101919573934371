import styled from "styled-components"
import { color, spacing, typography } from "./shared/styles"
import React from "react"

const Wrapper = styled.input`
  cursor: pointer;
  color: #FFFFFF;
  font-weight: ${typography.weight.medium};
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  display: block;
  padding: 10px 24px;
  
  appearance: none;
  
  background: ${color.primary};
  border-radius: ${spacing.borderRadius.default}px;
  border: 0;
  
  &:hover, &:active {
    background: #216ED5;
  }
`;

export default function InputButton({ ...props }) {
  return (
    <Wrapper {...props}/>
  )
}