import React, { Fragment } from 'react';
import styled from 'styled-components';
import Hero from './Hero';
import Footer from '../../layout/Footer';

import {
  breakpoint,
  color,
  pageMargins,
  typography,
  background,
  pageMargin, background as $background, layout
} from "../../shared/styles"
import SiteHeader from '../../layout/SiteHeader';
import ValueProp from '../../layout/ValueProp';
import Expense from './Expense';
import FaqItem from './FaqItem';
import LockSVG from '../../../assets/lock.svg';
import BankSVG from '../../../assets/bank.svg';
import FingerprintSVG from '../../../assets/fingerprint.svg';
import CheckSVG from '../../../assets/check.svg';
import StarSVG from '../../../assets/star.svg';
import GroupPNG from '../../../assets/group.png';
import SplitBillsPNG from '../../../assets/split-bills.png';
import PayPNG from '../../../assets/pay.png';
import Section from "../../layout/Section"
import ColumnLayout from "../../layout/ColumnLayout"
import Phone from "../../layout/Phone"
import FeatureSplitPNG from '../../../assets/feature/split.png';
import FeatureWalletPNG from '../../../assets/feature/wallet.png';
import FeatureChatPNG from '../../../assets/feature/chat.png';
import CardPNG from '../../../assets/feature/card.png';
import AvatarNatePNG from '../../../assets/avatar-nate.png';
import AvatarJamiePNG from '../../../assets/avatar-jamie.png';
import AvatarBrittaPNG from '../../../assets/avatar-britta.png';
import AvatarMaryPNG from '../../../assets/avatar-mary.png';
import AvatarJeffPNG from '../../../assets/avatar-jeff.png';
import AvatarRebeccaPNG from '../../../assets/avatar-rebecca.png';
import AvatarTomPNG from '../../../assets/avatar-tom.png';
import SplitExpensesSVG from '../../../assets/split-expenses.svg';
import ConfirmSVG from '../../../assets/confirm.svg';
import CardSVG from '../../../assets/feature/card.svg';
import HeartSVG from '../../../assets/heart.svg';
import Testimonial from "./Testimonial"
import SectionHeader from "../../layout/SectionHeader"

const Steps = styled.div`
  display: grid;
  grid-template-columns: auto 190px auto 190px auto;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StepArrow = styled.div`
  margin-top: 40px;
`;

const StepArrowTwo = styled.div`
  margin-top: 70px;
`;

const SomeTitle = styled.div`
  margin-top: 30px;
  font-size: ${typography.size.h3}px;
  font-weight: ${typography.weight.semibold};
`;

const SomeParagraph = styled.div`
  font-size: ${typography.size.p2}px;
  color: ${color.textSecondary};
  text-align: center;
  margin: 12px 32px 0;
`;

const Expenses = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

const SecureGrid = styled.div`
  width: 800px;
  margin: 0 auto;
  
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  row-gap: 34px;
  column-gap: 34px;
`;

const SecureRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const SecureItem = styled.div`
  width: 100%;
  display: flex;

  background: ${background.bg};
  border-radius: 12px;
  padding: 16px;

  /*
  @media (min-width: ${breakpoint}px) {
    width: 400px;
  }*/

  svg {
    margin-right: 16px;
  }
`;

const GroupAvatars = styled.div`
  display: grid;
  grid-template-columns: 16px 56px 16px 16px 56px 16px;
  grid-template-rows: 16px 56px 16px 16px 56px 16px;
`;

const AvatarMary = styled.div`
  width: 72px;
  height: 72px;
  background-image: url(${AvatarMaryPNG});
  background-repeat: no-repeat;
  background-size: contain;
  
  grid-column-start: 1;
  grid-column-end: 2;
  
  grid-row-start: 2;
  grid-row-end: 3;
`;

const AvatarJeff = styled.div`
  width: 72px;
  height: 72px;
  background-image: url(${AvatarJeffPNG});
  background-repeat: no-repeat;
  background-size: contain;
  
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
`;

const AvatarTom = styled.div`
  width: 72px;
  height: 72px;
  background-image: url(${AvatarTomPNG});
  background-repeat: no-repeat;
  background-size: contain;
  
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 6;
`;

const AvatarRebecca = styled.div`
  width: 72px;
  height: 72px;
  background-image: url(${AvatarRebeccaPNG});
  background-repeat: no-repeat;
  background-size: contain;
  
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 4;
  grid-row-end: 6;
`;

const AvatarNate = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${AvatarNatePNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const AvatarJamie = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${AvatarJamiePNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const AvatarBritta = styled.div`
  width: 36px;
  height: 36px;
  background-image: url(${AvatarBrittaPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const FeatureSplitScreen = styled.div`
  width: 245px;
  height: 530px;
  
  background-image: url(${FeatureSplitPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const FeatureWalletScreen = styled.div`
  width: 245px;
  height: 530px;
  
  background-image: url(${FeatureWalletPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const FeatureChatScreen = styled.div`
  width: 245px;
  height: 530px;
  
  background-image: url(${FeatureChatPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const FeatureCard = styled.div`
  width: 412px;
  height: 354px;
  
  background-image: url(${CardPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

export default function IndexScreen() {
  return (
    <>
      <Hero />
      {/*
      <Section>
        <SectionHeader>Split Anything Quickly and Easily</SectionHeader>
        <Expenses>
          <Expense
            title="Birthday Gift for Jane"
            amount="$100"
            shareAmount="$25"
            type="Split equally"
          />
          <Expense title="Rent" amount="$3300" shareAmount="$1200" type="Exact amount" />
          <Expense title="Lunch" amount="$44.24" shareAmount="$11.06" type="Split equally" />
          <Expense title="Plane Tickets" amount="$673.10" shareAmount="$336.55" type="Percentage" />
          <Expense title="Care Rental" amount="$294" shareAmount="$73.50" type="Shares" />
        </Expenses>
      </Section>*/}
      <Section>
        <ValueProp
          orientation="left"
          media={<Phone><FeatureSplitScreen/></Phone>}
          title="Easily Split Expenses"
          desc="Split one-time bills like drinks, dinner, or even recurring bills like rent, utilities, Netflix, and more. No need for calculators, spreadsheets, reminders, or slow payment apps. Just enter the amount, select friends, and Splyt takes care of the rest."
        />
      </Section>
      <Section backgroundColor={$background.bg}>
        <ValueProp
          orientation="right"
          media={<Phone><FeatureChatScreen/></Phone>}
          title="Do It All With Groups"
          desc="No need for multiple apps to coordinate group spending — split, save, pay, invite friends, track expenses, coordinate plans, set permissions, and chat."
        />
      </Section>
      <Section>
        <ValueProp
          orientation="left"
          media={<CardSVG width="412" height="354"/>}
          title="Pay Anywhere Using the Splyt Card"
          desc="The Splyt Card makes paying super convenient in-person, online, with Apple Pay®, Google Pay™, or Samsung Pay. There are no credit checks or hidden fees."
        />
      </Section>
      {/*
      <Section>
        <SectionHeader>Splyt is 100% safe and secure</SectionHeader>
        <SecureGrid>
          <SecureItem>
            <LockSVG /> Industry leading security standards
          </SecureItem>
          <SecureItem>
            <BankSVG /> Bank-level 256-bit encryption
          </SecureItem>
          <SecureItem>
            <FingerprintSVG /> App PIN / fingerprint identification
          </SecureItem>
          <SecureItem>
            <CheckSVG /> Real-time card controls
          </SecureItem>
        </SecureGrid>
      </Section>
      */}
      <Section>
        <SectionHeader>See why people <HeartSVG/> Splyt</SectionHeader>
        <ColumnLayout columns="1,1,1">
          <Testimonial rating={5} authorAvatar={<AvatarNate/>} authorName="Nate">
            This is an absolute game changer for sharing expenses with my roommates. Can't imagine being without Splyt again!
          </Testimonial>
          <Testimonial rating={5} authorAvatar={<AvatarJamie/>} authorName="Jamie">
            I starting using Splyt for a bachelorette party and it made everything super easy to keep track of. We now use it for all of our trips and gatherings!
          </Testimonial>
          <Testimonial rating={5} authorAvatar={<AvatarBritta/>} authorName="Britta">
            We use Splyt constantly with our friends and family. We put every group expense in there no matter how big or small.
          </Testimonial>
        </ColumnLayout>
      </Section>
      <Section>
        <SectionHeader>Frequently Asked Questions</SectionHeader>
        <FaqItem
          title="What is Splyt?"
          desc="Splyt helps group of people to pool together funds so they can pay together. It works like magic and eliminates the need for complex calculations or awkward conversations about who owes who what."
          expanded={false} />
        <FaqItem 
          title="Is Splyt really free?" 
          desc="Yes it’s really free to use Splyt there are no hidden fees!"
          expanded={false} />
        <FaqItem 
          title="What banks or payment methods are supported?" 
          desc="We support every major US bank and credit card."
          expanded={false} />
         <FaqItem 
          title="Do you run credit checks? Does it appear on my credit report?" 
          desc="We do not perform credit checks and do not report to the credit bureaus. Personal data that you provide us with is strictly only used to verify your identity as per Federal legal requirements."
          expanded={false} />
      </Section>
      <Footer />
    </>
  );
}
